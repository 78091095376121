import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfDay } from 'date-fns';

const getDate = (date?: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

const endOfToday = () => {
  const date = new Date();
  const eod = endOfDay(date);

  return eod.toISOString();
};

export interface States {
  listBookingsTodayEndCursor: string;
  listBookingsTodayEndTime: string | null;
  listBookingsTodayOrderBy: OrderBy;
  listBookingsTodayPageNumber: number;
  listBookingsTodayPageSize: number;
  listBookingsTodayRowIndex: number;
  listBookingsTodaySearchTerm: string;
  listBookingsTodayStartTime: string | null;
}

const initialState: States = {
  listBookingsTodayEndCursor: '',
  listBookingsTodayEndTime: endOfToday(),
  listBookingsTodayOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsTodayPageNumber: 0,
  listBookingsTodayPageSize: 10,
  listBookingsTodayRowIndex: -1,
  listBookingsTodaySearchTerm: '',
  listBookingsTodayStartTime: getDate(),
};

export const bookingsListBookingsTodaySlice = createSlice({
  initialState,
  name: 'bookingsListBookingsToday',
  reducers: {
    setListBookingsTodayEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsTodayEndCursor = payload;
    },
    setListBookingsTodayEndTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsTodayEndTime = payload;
    },
    setListBookingsTodayOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsTodayOrderBy = payload;
    },
    setListBookingsTodayPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsTodayPageNumber = payload;
    },
    setListBookingsTodayPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsTodayPageSize = payload;
    },
    setListBookingsTodayRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsTodayRowIndex = payload;
    },
    setListBookingsTodaySearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsTodaySearchTerm = payload;
    },
    setListBookingsTodayStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsTodayStartTime = payload;
    },
  },
});

export default bookingsListBookingsTodaySlice.reducer;

export const {
  setListBookingsTodayEndCursor: setListBookingsTodayEndCursorAction,
  setListBookingsTodayEndTime: setListBookingsTodayEndTimeAction,
  setListBookingsTodayPageNumber: setListBookingsTodayPageNumberAction,
  setListBookingsTodayPageSize: setListBookingsTodayPageSizeAction,
  setListBookingsTodayRowIndex: setListBookingsTodayRowIndexAction,
  setListBookingsTodaySearchTerm: setListBookingsTodaySearchTermAction,
  setListBookingsTodayStartTime: setListBookingsTodayStartTimeAction,
} = bookingsListBookingsTodaySlice.actions;
