import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';

const startOfToday = () => {
  const date = new Date();
  const sod = startOfDay(date);

  return sod.toISOString();
};

const getDate = (date?: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  listBookingsCompletedEndCursor: string;
  listBookingsCompletedEndTime: string | null;
  listBookingsCompletedOrderBy: OrderBy;
  listBookingsCompletedPageNumber: number;
  listBookingsCompletedPageSize: number;
  listBookingsCompletedRowIndex: number;
  listBookingsCompletedSearchTerm: string;
}

const initialState: States = {
  listBookingsCompletedEndCursor: '',
  listBookingsCompletedEndTime: getDate(),
  listBookingsCompletedOrderBy: {
    direction: Direction.Desc,
    field: 'startTime',
  },
  listBookingsCompletedPageNumber: 0,
  listBookingsCompletedPageSize: 10,
  listBookingsCompletedRowIndex: -1,
  listBookingsCompletedSearchTerm: '',
};

export const bookingsListBookingsCompletedSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsCompleted',
  reducers: {
    setListBookingsCompletedEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsCompletedEndCursor = payload;
    },
    setListBookingsCompletedOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsCompletedOrderBy = payload;
    },
    setListBookingsCompletedPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCompletedPageNumber = payload;
    },
    setListBookingsCompletedPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCompletedPageSize = payload;
    },
    setListBookingsCompletedRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCompletedRowIndex = payload;
    },
    setListBookingsCompletedSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsCompletedSearchTerm = payload;
    },
  },
});

export default bookingsListBookingsCompletedSlice.reducer;

export const {
  setListBookingsCompletedEndCursor: setListBookingsCompletedEndCursorAction,
  setListBookingsCompletedPageNumber: setListBookingsCompletedPageNumberAction,
  setListBookingsCompletedPageSize: setListBookingsCompletedPageSizeAction,
  setListBookingsCompletedRowIndex: setListBookingsCompletedRowIndexAction,
  setListBookingsCompletedSearchTerm: setListBookingsCompletedSearchTermAction,
} = bookingsListBookingsCompletedSlice.actions;
