import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfDay } from 'date-fns';

const endOfToday = () => {
  const date = new Date();
  const eod = endOfDay(date);

  return eod.toISOString();
};

export interface States {
  listBookingsUpcomingEndCursor: string;
  listBookingsUpcomingOrderBy: OrderBy;
  listBookingsUpcomingPageNumber: number;
  listBookingsUpcomingPageSize: number;
  listBookingsUpcomingRowIndex: number;
  listBookingsUpcomingSearchTerm: string;
  listBookingsUpcomingStartTime: string | null;
}

const initialState: States = {
  listBookingsUpcomingEndCursor: '',
  listBookingsUpcomingOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsUpcomingPageNumber: 0,
  listBookingsUpcomingPageSize: 10,
  listBookingsUpcomingRowIndex: -1,
  listBookingsUpcomingSearchTerm: '',
  listBookingsUpcomingStartTime: endOfToday(),
};

export const bookingsListBookingsUpcomingSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsUpcoming',
  reducers: {
    setListBookingsUpcomingEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsUpcomingEndCursor = payload;
    },
    setListBookingsUpcomingOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsUpcomingOrderBy = payload;
    },
    setListBookingsUpcomingPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsUpcomingPageNumber = payload;
    },
    setListBookingsUpcomingPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsUpcomingPageSize = payload;
    },
    setListBookingsUpcomingRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsUpcomingRowIndex = payload;
    },
    setListBookingsUpcomingSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsUpcomingSearchTerm = payload;
    },
  },
});

export default bookingsListBookingsUpcomingSlice.reducer;

export const {
  setListBookingsUpcomingEndCursor: setListBookingsUpcomingEndCursorAction,
  setListBookingsUpcomingPageNumber: setListBookingsUpcomingPageNumberAction,
  setListBookingsUpcomingPageSize: setListBookingsUpcomingPageSizeAction,
  setListBookingsUpcomingRowIndex: setListBookingsUpcomingRowIndexAction,
  setListBookingsUpcomingSearchTerm: setListBookingsUpcomingSearchTermAction,
} = bookingsListBookingsUpcomingSlice.actions;
