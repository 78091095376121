import { configureStore } from '@reduxjs/toolkit';
import { availabilitySlice } from 'features/Availability/store';
import { bookingsEditBookingSlice } from 'features/Bookings/EditBooking/store';
import { bookingsListBookingsCancelledSlice } from 'features/Bookings/ListBookingsCancelled/store';
import { bookingsListBookingsCompletedSlice } from 'features/Bookings/ListBookingsCompleted/store';
import { bookingsListBookingsTodaySlice } from 'features/Bookings/ListBookingsToday/store';
import { bookingsListBookingsUpcomingSlice } from 'features/Bookings/ListBookingsUpcoming/store';
import { settingsSlice } from 'features/Settings/EditProfile/store';
import { useDispatch } from 'react-redux';
import { authSlice } from './auth';
import { uiSlice } from './ui';

export const store = configureStore({
  devTools: true,
  reducer: {
    auth: authSlice.reducer,
    availability: availabilitySlice.reducer,
    bookingsEditBooking: bookingsEditBookingSlice.reducer,
    bookingsListBookingsCancelled: bookingsListBookingsCancelledSlice.reducer,
    bookingsListBookingsCompleted: bookingsListBookingsCompletedSlice.reducer,
    bookingsListBookingsToday: bookingsListBookingsTodaySlice.reducer,
    bookingsListBookingsUpcoming: bookingsListBookingsUpcomingSlice.reducer,
    settings: settingsSlice.reducer,
    ui: uiSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch();
