import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AvailabiltyState {
  date: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
  l21: string;
  l22: string;
  l23: string;
}

const initialState: AvailabiltyState = {
  date: '',
  l10: '',
  l11: '',
  l12: '',
  l13: '',
  l14: '',
  l15: '',
  l16: '',
  l17: '',
  l18: '',
  l19: '',
  l20: '',
  l21: '',
  l22: '',
  l23: '',
};

interface SetSlotStatePayload {
  clubhouseCode: string;
  time: number;
}

export const availabilitySlice = createSlice({
  initialState,
  name: 'availability',
  reducers: {
    setDate: (state, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
    setSlotState: (state, { payload }: PayloadAction<SetSlotStatePayload>) => {
      const { clubhouseCode, time } = payload;
      const accessor = `l${time}` as keyof AvailabiltyState;
      state[accessor] = clubhouseCode;
    },
  },
});

export default availabilitySlice.reducer;

export const { setDate: setDateAction, setSlotState: setSlotStateAction } =
  availabilitySlice.actions;
